import { FinTechComponent } from './modules/fintech/fintech.component';
import { FinTechModule } from './modules/fintech/fintech.module';
import { NPModule } from './modules/np/np.module';
import { NPComponent } from './modules/np/np.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { SosBookingComponent } from './modules/sos-booking/sos-booking.component';
import { FittoComponent } from './modules/fitto/fitto.component';
import { ZimitComponent } from './modules/zimit/zimit.component';
import { PortfolioComponent } from './modules/portfolio/portfolio.component';
import { VodafoneComponent } from './modules/vodafone/vodafone.component';
import { BasfComponent } from './modules/basf/basf.component';
import { EDMSComponent } from './modules/edms/edms.component';
import { SW2Component } from './modules/sw2/sw2.component';
import { VodafoneModule } from './modules/vodafone/vodafone.module';
import { SosBookingModule } from './modules/sos-booking/sos-booking.module';
import { SW2Module } from './modules/sw2/sw2.module';
import { FittoModule } from './modules/fitto/fitto.module';
import { ZimitModule } from './modules/zimit/zimit.module';
import { BasfModule } from './modules/basf/basf.module';
import { EDMSModule } from './modules/edms/edms.module';
import { PortalModule } from '@angular/cdk/portal';
import {} from './modules/portfolio/portfolio.module';
import { DeliveryComponent } from './modules/delivery/delivery.component';
import { DeliveryModule } from './modules/delivery/delivery.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: './modules/main/main.module#MainModule',
  },
  {
    path: 'services',
    loadChildren: './modules/services/services.module#ServicesModule',
  },
  // {
  //   path: 'sos-booking',
  //   component: SosBookingComponent,
  // },
  {
    path: 'vodafone',
    component: VodafoneComponent,
  },
  {
    path: 'fitto',
    component: FittoComponent,
  },
  {
    path: 'zimit',
    component: ZimitComponent,
  },
  {
    path: 'basf',
    component: BasfComponent,
  },
  {
    path: 'edms',
    component: EDMSComponent,
  },
  // {
  //   path: 'sw2',
  //   component: SW2Component,
  // },
  {
    path: 'optimized-warehouse',
    component: NPComponent,
  },
  // {
  //   path: 'fintech',
  //   component: FinTechComponent,
  // },
  {
    path: 'delivery',
    component: DeliveryComponent,
  },
  {
    path: 'portfolio',
    loadChildren: './modules/portfolio/portfolio.module#PortfolioModule',
  },
  {
    path: 'delivery-process',
    loadChildren:
      './modules/delivery-process/delivery-process.module#DeliveryProcessModule',
  },
  {
    path: 'company',
    loadChildren: './modules/our-company/our-company.module#OurCompanyModule',
  },
  {
    path: 'technologies',
    loadChildren:
      './modules/technologies/technologies.module#TechnologiesModule',
  },
  {
    path: 'lets-talk',
    loadChildren: './modules/lets-talk/lets-talk.module#LetsTalkModule',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
    VodafoneModule,
    SosBookingModule,
    SW2Module,
    FittoModule,
    ZimitModule,
    BasfModule,
    EDMSModule,
    NPModule,
    FinTechModule,
    DeliveryModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
