import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, NavigationStart } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'app-head-menu',
  templateUrl: './head-menu.component.html',
  styleUrls: ['./head-menu.component.scss'],
})
export class HeadMenuComponent implements OnInit {
  smallHeadMenu = false;
  blackClass = false;
  showMobileMenu = false;
  isCloseMobileMenu = true;

  constructor(public router: Router) {}

  ngOnInit() {
    this.initLogoAnimation();
    window.addEventListener('scroll', () => {
      this.setHeaderColor();
      this.setHeaderSize();
    });
  }

  ngOnDestroy(): void {}

  initLogoAnimation() {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event: any) => {
      this.setHeaderColor();
      if (event instanceof NavigationStart) {
        $('.circle').removeClass((index) => {
          return `circle-${index}`;
        });
      }
      if (event instanceof NavigationEnd) {
        $('.circle').addClass((index) => {
          return `circle-${index}`;
        });
      }
    });
  }

  setHeaderColor() {
    const value: number = window.pageYOffset;
    const height: number = window.innerHeight;
    if (this.router.url === '/') {
      const headerHeight = 64; //
      const startValue: number = height - headerHeight;
      const portfolio = document.getElementById('case_studies');
      const firstSection = document.getElementById('hero-section');
      if (portfolio) {
        if (
          value >= firstSection.offsetTop &&
          value < firstSection.offsetTop + firstSection.offsetHeight
        ) {
          this.blackClass = false;
        } else if (value < portfolio.offsetTop) {
          this.blackClass = true;
        } else if (
          value >= portfolio.offsetTop &&
          value < portfolio.offsetTop + portfolio.offsetHeight
        ) {
          this.blackClass = false;
        } else {
          this.blackClass = true;
        }
      } else if (value >= startValue && value <= startValue + 745) {
        this.blackClass = true;
      } else if (value >= startValue + 745 + height * 3) {
        this.blackClass = true;
      } else {
        this.blackClass = false;
      }
    } else {
      this.blackClass = true;
    }
    if (this.router.url === '/sos-booking') {
      this.blackClass = false;
      const headerHeight: number = 64; //
      const startValue: number = height - headerHeight;
      if (value > 2 * startValue + 138 + 64 && value <= 4 * startValue) {
        this.blackClass = true;
      } else if (value > 6 * startValue - 130) {
        this.blackClass = true;
      }
    }
    if (this.router.url === '/vodafone') {
      this.blackClass = false;
      const headerHeight: number = 64; //
      const startValue: number = height - headerHeight;
      let mobile = document.getElementById('vodafone-mob-app'),
        cards1 = document.getElementById('vodafone-cards1'),
        cards2 = document.getElementById('vodafone-cards2'),
        cards3 = document.getElementById('vodafone-cards3'),
        cards4 = document.getElementById('vodafone-cards4'),
        cards5 = document.getElementById('vodafone-cards5');
      if (value > 2 * startValue + 64 && value <= mobile.offsetTop) {
        this.blackClass = true;
      } else if (
        value > mobile.offsetTop + mobile.offsetHeight - 64 &&
        value <= cards1.offsetTop - 64
      ) {
        this.blackClass = true;
      } else if (
        value > cards1.offsetTop + cards1.offsetHeight - 64 &&
        value <= cards2.offsetTop - 64
      ) {
        this.blackClass = true;
      } else if (
        value > cards3.offsetTop + cards3.offsetHeight - 64 &&
        value <= cards4.offsetTop - 64
      ) {
        this.blackClass = true;
      } else if (
        value > cards4.offsetTop + cards4.offsetHeight - 64 &&
        value <= cards5.offsetTop - 64
      ) {
        this.blackClass = true;
      } else if (value > cards5.offsetTop + cards5.offsetHeight - 64) {
        this.blackClass = true;
      }
    }
    if (this.router.url === '/fitto') {
      this.blackClass = false;
      const headerHeight: number = 64; //
      const startValue: number = height - headerHeight;
      let images = document.getElementById('fitto-images'),
        cards1 = document.getElementById('fitto-cards-top'),
        cards2 = document.getElementById('fitto-cards-bottom');
      if (
        value > images.offsetTop - headerHeight / 2 &&
        value <= cards1.offsetTop - headerHeight / 2
      ) {
        this.blackClass = true;
      } else if (value > cards2.offsetTop + cards2.offsetHeight) {
        this.blackClass = true;
      }
    }
    if (this.router.url === '/zimit') {
      let dash = document.getElementById('zimit-dash');
      if (value > dash.offsetTop - 32) {
        this.blackClass = true;
      } else {
        this.blackClass = false;
      }
    }
    if (this.router.url === '/basf') {
      let imgs = document.getElementById('basf-images');
      if (value > imgs.offsetTop - 32) {
        this.blackClass = true;
      } else {
        this.blackClass = false;
      }
    }
    if (this.router.url === '/edms') {
      let imgs = document.getElementById('edms-images');
      if (value > imgs.offsetTop - 32) {
        this.blackClass = true;
      } else {
        this.blackClass = false;
      }
    }
    if (this.router.url === '/optimized-warehouse') {
      let imgs = document.getElementById('np-images');
      if (value > imgs.offsetTop - 32) {
        this.blackClass = true;
      } else {
        this.blackClass = false;
      }
    }
    if (this.router.url === '/fintech') {
      let imgs = document.getElementById('fintech-images');
      let webappImage = document.getElementById('fintech-webapp-image');
      let mobappImages = document.getElementById('fintech-mobapp-images');
      if (value > imgs.offsetTop - 32) {
        this.blackClass = true;
        if (
          (value > webappImage.offsetTop - 32 &&
            value < webappImage.offsetTop + webappImage.offsetHeight - 64) ||
          (value > mobappImages.offsetTop - 32 &&
            value < mobappImages.offsetTop + mobappImages.offsetHeight - 64)
        ) {
          this.blackClass = false;
        } else {
          this.blackClass = true;
        }
      } else {
        this.blackClass = false;
      }
    }
    if (this.router.url === '/delivery') {
      let imgs = document.getElementById('delivery-images');
      let mobappImages = document.getElementById('delivery-mobapp-images');
      if (value > imgs.offsetTop - 32) {
        this.blackClass = true;
        if (
          value > mobappImages.offsetTop - 32 &&
          value < mobappImages.offsetTop + mobappImages.offsetHeight - 64
        ) {
          this.blackClass = false;
        } else {
          this.blackClass = true;
        }
      } else {
        this.blackClass = false;
      }
    }
    if (this.router.url === '/sw2') {
      this.blackClass = false;
      let imgs = document.getElementById('sw2-images'),
        screen = document.getElementById('sw2-screen');
      if (value > imgs.offsetTop - 32) {
        this.blackClass = true;
      }
      if (
        value > screen.offsetTop - 32 &&
        value <= screen.offsetTop + screen.offsetHeight - 32
      ) {
        this.blackClass = false;
      }
    }
    if (this.router.url === '/portfolio') {
      if (window.innerWidth < 1199) {
        if (value > document.getElementById('case_studies').offsetTop - 32) {
          this.blackClass = false;
        } else {
          this.blackClass = true;
        }
      } else {
        let vcase = document.getElementById('case_studies');
        if (value > vcase.offsetTop - 150) {
          this.blackClass = false;
        } else {
          this.blackClass = true;
        }
      }
    }
    if (height <= 730) {
      if (this.router.url === '/' && value > 7000) {
        this.blackClass = false;
      }
      if (this.router.url.startsWith('/services') && value > 3700) {
        this.blackClass = false;
      }
      if (this.router.url === '/company' && value > 2600) {
        this.blackClass = false;
      }
      if (this.router.url === '/delivery-process') {
        if (value > 90) {
          this.blackClass = true;
        } else {
          this.blackClass = false;
        }
      }
      if (
        this.router.url === '/technologies' &&
        value >
          document.getElementById('navigation-button-section').offsetTop - 32
      ) {
        this.blackClass = false;
      }
      if (this.router.url === '/lets-talk' && value > 820) {
        this.blackClass = false;
      }
    }
    if (this.router.url === '/delivery-process') {
      if (value > 90) {
        this.blackClass = false;
      } else {
        this.blackClass = true;
      }
    }
  }

  setHeaderSize() {
    const verticalOffset = window.pageYOffset;
    if (verticalOffset >= 70) {
      this.smallHeadMenu = true;
    } else {
      this.smallHeadMenu = false;
    }
  }
}
