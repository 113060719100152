import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZimitComponent } from './zimit.component';
import { SharedModule } from 'src/app/shared/module/shared.module';



@NgModule({
  declarations: [ZimitComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class ZimitModule { }
