import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeadMenuComponent} from './shared/components/head-menu/head-menu.component';
import {NavigationButtonsComponent} from './shared/components/navigation-buttons/navigation-buttons.component';
import {FooterComponent} from './shared/components/footer/footer.component';
import {MainModule} from './modules/main/main.module';
import {SharedModule} from './shared/module/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {HttpClientModule} from '@angular/common/http';

import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule, GoogleAnalyticsService} from 'ngx-google-analytics';

@NgModule({
  declarations: [
    AppComponent,
    HeadMenuComponent,
    NavigationButtonsComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MainModule,
    SharedModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    HttpClientModule,
    NgxGoogleAnalyticsModule.forRoot('UA-206277310-1'),
    NgxGoogleAnalyticsRouterModule.forRoot()
  ],
  providers: [GoogleAnalyticsService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
