import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/module/shared.module';
import { FinTechComponent } from './fintech.component';

@NgModule({
  declarations: [FinTechComponent],
  imports: [CommonModule, SharedModule],
})
export class FinTechModule {}
