import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../shared/module/shared.module';
import { SW2Component } from './sw2.component';



@NgModule({
  declarations: [SW2Component],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class SW2Module { }
