import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/module/shared.module';
import { MainComponent } from './main.component';
import { Routes, RouterModule } from '@angular/router';
import { FeedbackComponent } from './components/feedback/feedback.component';

const routes: Routes = [
  {
    path: '', component: MainComponent
  }
];

@NgModule({
  declarations: [
    MainComponent,
    FeedbackComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    MainComponent
  ]
})

export class MainModule {

}
