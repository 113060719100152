import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

interface Response {
  result: String;
  status: String;
}

@Component({
  selector: 'app-sos-booking',
  templateUrl: './sos-booking.component.html',
  styleUrls: ['./sos-booking.component.scss'],
})
export class SosBookingComponent implements OnInit {
  isShowPopUp: boolean = false;
  isClosedPopUp: boolean = false;
  isShowFormPopup: boolean = false;
  sendedForm: number = 0;
  isFormSubmitted: boolean = false;
  sosbookingConnectionForm: FormGroup;

  constructor(private fb: FormBuilder, private http: HttpClient) {}

  ngOnInit() {
    window.addEventListener('scroll', () => {
      this.showPopUp();
    });

    this.sosbookingConnectionForm = this.fb.group({
      nameControl: this.fb.control('', [
        Validators.required,
        Validators.minLength(2),
      ]),
      emailControl: this.fb.control('', [
        Validators.required,
        Validators.email,
      ]),
      fileControl: this.fb.control('', [Validators.required]),
    });


    this.sosbookingConnectionForm.setValue({
      nameControl: '',
      emailControl: '',
      fileControl: 'sosbooking',
    });
  }

  showPopUp() {
    if (this.isClosedPopUp) {
      this.isShowPopUp = false;
    } else {
      const value: number = window.pageYOffset;
      const height: number = window.innerHeight;
      const headerHeight: number = 64; //
      const startValue: number = height - headerHeight;
      const windowWidth: number = window.innerWidth;

      if (windowWidth < 1119) {
        this.isShowPopUp = true;
      } else {
        if (value > 2 * startValue + 138 + 64 && value < 6 * startValue - 420) {
          this.isShowPopUp = true;
        } else {
          this.isShowPopUp = false;
        }
      }
    }
  }

  clickOpenFormEvent() {
    this.isShowFormPopup = true;
    this.isFormSubmitted = false;
  }

  clickCloseFormEvent() {
    this.isShowFormPopup = false;
  }

  clickCloseEvent() {
    this.isClosedPopUp = true;
    this.isShowPopUp = false;
  }

  submitForm(): void {
    this.isFormSubmitted = true;
    const formData: FormData = new FormData();
    formData.append('name', this.sosbookingConnectionForm.value.nameControl);
    formData.append('email', this.sosbookingConnectionForm.value.emailControl);
    formData.append('file', 'sosbooking');
    try {
      this.http
        .post('sendMail/sendCaseStudy.php', formData)
        .subscribe((res: Response) => {
          if (res.result === 'success') {
            // alert('Message sent');
            // console.log(res.status)
            // this.basfConnectionForm.reset();
            this.sendedForm = 1;
          } else if (res.result === 'error') {
            // console.log(res.status)
            this.sendedForm = -1;
          }
        });
    } catch (e) {
      console.log(e);
      this.sendedForm = -1;
    }
  }
}
