import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/module/shared.module';
import { NPComponent } from './np.component';

@NgModule({
  declarations: [NPComponent],
  imports: [CommonModule, SharedModule],
})
export class NPModule {}
