import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {

  feedbackSlides = [
    {
      img: 'assets/images/main/users_feedback/user1.png',
      text: 'I came up with an idea to create an App making it easy to find any service around you. That’s when I came across Infinity Technologies AG. We brainstormed the idea with the assigned team and started to work on the design, architecture, development of an App for the customers, and then web-admin panel for service providers <br> <br> Over time more and more tasks appeared. The team fulfilled all my expectations and we continue to work further!',
      name: 'Elena Schaerli'
    },
    {
      img: 'assets/images/main/users_feedback/user2.PNG',
      text: 'The key point to success was Agile methodology. The working process was based on daily meetings and weekly sprints, which determined effective time estimation and delivered qualitative product within short time-frames. <br> <br> The work turned out great! It was my pleasure to collaborate with Infinity Technologies team.',
      name: 'Ari Friedrich'
    },
  ];
  slideConfig = { slidesToShow: 1, slidesToScroll: 1 };

  constructor () { }

  ngOnInit() {
  }

}
