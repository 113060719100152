import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { getTreeMissingMatchingNodeDefError } from '@angular/cdk/tree';

@Component({
  selector: 'app-navigation-buttons',
  templateUrl: './navigation-buttons.component.html',
  styleUrls: ['./navigation-buttons.component.scss'],
})
export class NavigationButtonsComponent implements OnInit {
  leftLinkText: string = '';
  leftTitleText: string = '';
  rightLinkText: string = '';
  rightTitleText: string = '';
  leftSideColor: string = '';
  rightSideColor: string = '';
  leftSideTitleColor: string = '';
  rightSideTitleColor: string = '';
  leftSideLinkColor: string = '';
  rightSideLinkColor: string = '';
  rightSideSubtitle: string = '';

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event: any) => {
      if (event.url && event.url === '/') {
        this.leftLinkText = `Let's talk`;
        this.rightLinkText = 'Our company';
        this.leftTitleText = 'Get in touch';
        this.rightTitleText = 'Find out more';
        this.rightSideSubtitle = '';

        this.leftSideColor = '#0049A2';
        this.rightSideColor = '#E6E9F0';
        this.leftSideTitleColor = '#FFFFFF';
        this.rightSideTitleColor = '#000000';
        this.leftSideLinkColor = '#FFFFFF';
        this.rightSideLinkColor = '#000000';
      }
      if (event.url && event.url === '/company') {
        this.leftLinkText = `Let's talk`;
        this.rightLinkText = 'Delivery Process';
        this.leftTitleText = 'Get in touch';
        this.rightTitleText = 'Find out more';
        this.rightSideSubtitle = '';

        this.leftSideColor = '#0049A2';
        this.rightSideColor = '#E6E9F0';
        this.leftSideTitleColor = '#FFFFFF';
        this.rightSideTitleColor = '#000000';
        this.leftSideLinkColor = '#FFFFFF';
        this.rightSideLinkColor = '#000000';
      }
      if (event.url && event.url === '/delivery-process') {
        this.leftLinkText = `Let's talk`;
        this.rightLinkText = 'Services';
        this.leftTitleText = 'Get in touch';
        this.rightTitleText = 'Find out more';
        this.rightSideSubtitle = '';

        this.leftSideColor = '#0049A2';
        this.rightSideColor = '#E6E9F0';
        this.leftSideTitleColor = '#FFFFFF';
        this.rightSideTitleColor = '#000000';
        this.leftSideLinkColor = '#FFFFFF';
        this.rightSideLinkColor = '#000000';
      }
      if (event.url && event.url === '/services') {
        this.leftLinkText = `Let's talk`;
        this.rightLinkText = 'Technologies';
        this.leftTitleText = 'Get in touch';
        this.rightTitleText = 'Find out more';
        this.rightSideSubtitle = '';

        this.leftSideColor = '#0049A2';
        this.rightSideColor = '#E6E9F0';
        this.leftSideTitleColor = '#FFFFFF';
        this.rightSideTitleColor = '#000000';
        this.leftSideLinkColor = '#FFFFFF';
        this.rightSideLinkColor = '#000000';
      }
      if (event.url && event.url === '/technologies') {
        this.leftLinkText = `Let's talk`;
        this.rightLinkText = 'Portfolio';
        this.leftTitleText = 'Get in touch';
        this.rightTitleText = 'Find out more';
        this.rightSideSubtitle = '';

        this.leftSideColor = '#0049A2';
        this.rightSideColor = '#E6E9F0';
        this.leftSideTitleColor = '#FFFFFF';
        this.rightSideTitleColor = '#000000';
        this.leftSideLinkColor = '#FFFFFF';
        this.rightSideLinkColor = '#000000';
      }
      if (event.url && event.url === '/portfolio') {
        this.leftLinkText = `Let's talk`;
        this.rightLinkText = 'Our company';
        this.leftTitleText = 'Get in touch';
        this.rightTitleText = 'Find out more';
        this.rightSideSubtitle = '';

        this.leftSideColor = '#0049A2';
        this.rightSideColor = '#E6E9F0';
        this.leftSideTitleColor = '#FFFFFF';
        this.rightSideTitleColor = '#000000';
        this.leftSideLinkColor = '#FFFFFF';
        this.rightSideLinkColor = '#000000';
      }
      if (event.url && event.url === '/lets-talk') {
        this.leftLinkText = `Let's talk`;
        this.rightLinkText = 'Technologies';
        this.leftTitleText = 'Get in touch';
        this.rightTitleText = 'Find out more';
        this.rightSideSubtitle = '';

        this.leftSideColor = '#0049A2';
        this.rightSideColor = '#E6E9F0';
        this.leftSideTitleColor = '#FFFFFF';
        this.rightSideTitleColor = '#000000';
        this.leftSideLinkColor = '#FFFFFF';
        this.rightSideLinkColor = '#000000';
      }
      if (event.url && event.url === '/fitto') {
        this.leftLinkText = `Let's talk`;
        this.rightLinkText = 'Next Case';
        this.leftTitleText = 'Get in touch';
        this.rightTitleText = 'Quote Analytics';
        this.rightSideSubtitle = 'for a Global Technology Corporation';

        this.leftSideColor = '#C4C4C4';
        this.rightSideColor = '#49A7AE';
        this.leftSideTitleColor = '#656565';
        this.rightSideTitleColor = '#FFFFFF';
        this.leftSideLinkColor = '#939292';
        this.rightSideLinkColor = '#FFFFFF';
      }
      if (event.url && event.url === '/vodafone') {
        this.leftLinkText = `Let's talk`;
        this.rightLinkText = 'Next Case';
        this.leftTitleText = 'Get in touch';
        this.rightTitleText = 'IoT System';
        this.rightSideSubtitle = 'That Turns Healthy Lifestyles On';

        this.leftSideColor = '#C4C4C4';
        this.rightSideColor = '#3D6BCB';
        this.leftSideTitleColor = '#656565';
        this.rightSideTitleColor = '#FFFFFF';
        this.leftSideLinkColor = '#939292';
        this.rightSideLinkColor = '#FFFFFF';
      }
      if (event.url && event.url === '/sos-booking') {
        this.leftLinkText = `Let's talk`;
        this.rightLinkText = 'Next Case';
        this.leftTitleText = 'Get in touch';
        this.rightTitleText = 'VCM';
        this.rightSideSubtitle = 'for a Global Mobile Communication Company';

        this.leftSideColor = '#C4C4C4';
        this.rightSideColor = '#E60000';
        this.leftSideTitleColor = '#656565';
        this.rightSideTitleColor = '#FFFFFF';
        this.leftSideLinkColor = '#939292';
        this.rightSideLinkColor = '#FFFFFF';
      }
      if (event.url && event.url === '/zimit') {
        this.leftLinkText = `Let's talk`;
        this.rightLinkText = 'Next Case';
        this.leftTitleText = 'Get in touch';
        this.rightTitleText = 'Product Portfolio Solution';
        this.rightSideSubtitle =
          'that Enables Cross-Continental Collaboration between Presale Teams';

        this.leftSideColor = '#C4C4C4';
        this.rightSideColor = '#104995';
        this.leftSideTitleColor = '#656565';
        this.rightSideTitleColor = '#FFFFFF';
        this.leftSideLinkColor = '#939292';
        this.rightSideLinkColor = '#FFFFFF';
      }
      if (event.url && event.url === '/basf') {
        this.leftLinkText = `Let's talk`;
        this.rightLinkText = 'Next Case';
        this.leftTitleText = 'Get in touch';
        this.rightTitleText = 'EDMS';
        this.rightSideSubtitle =
          'Electronic Document Management System with Jira-alike Flow ';

        this.leftSideColor = '#C4C4C4';
        this.rightSideColor = '#2EBA77';
        this.leftSideTitleColor = '#656565';
        this.rightSideTitleColor = '#FFFFFF';
        this.leftSideLinkColor = '#939292';
        this.rightSideLinkColor = '#FFFFFF';
      }
      if (event.url && event.url === '/edms') {
        // this.leftLinkText = `Let's talk`;
        // this.rightLinkText = 'Next Case';
        // this.leftTitleText = 'Get in touch';
        // this.rightTitleText = 'EdTech Tool';
        // this.rightSideSubtitle = 'That Connects Peers To Study Better ';

        // this.leftSideColor = '#C4C4C4';
        // this.rightSideColor = '#6d0441';
        // this.leftSideTitleColor = '#656565';
        // this.rightSideTitleColor = '#FFFFFF';
        // this.leftSideLinkColor = '#939292';
        // this.rightSideLinkColor = '#FFFFFF';

        this.leftLinkText = `Let's talk`;
        this.rightLinkText = 'Next Case';
        this.leftTitleText = 'Get in touch';
        this.rightTitleText = 'Optimized Warehouse Process';
        this.rightSideSubtitle =
          'Saves 4 Million USD per Annum for a Nation-Wide Logistics Operator ';

        this.leftSideColor = '#C4C4C4';
        this.rightSideColor = '#0049A2';
        this.leftSideTitleColor = '#656565';
        this.rightSideTitleColor = '#FFFFFF';
        this.leftSideLinkColor = '#939292';
        this.rightSideLinkColor = '#FFFFFF';
      }
      if (event.url && event.url === '/sw2') {
        this.leftLinkText = `Let's talk`;
        this.rightLinkText = 'Next Case';
        this.leftTitleText = 'Get in touch';
        this.rightTitleText = 'Optimized Warehouse Process';
        this.rightSideSubtitle =
          'Saves 4 Million USD per Annum for a Nation-Wide Logistics Operator ';

        this.leftSideColor = '#C4C4C4';
        this.rightSideColor = '#0049A2';
        this.leftSideTitleColor = '#656565';
        this.rightSideTitleColor = '#FFFFFF';
        this.leftSideLinkColor = '#939292';
        this.rightSideLinkColor = '#FFFFFF';
      }
      if (event.url && event.url === '/optimized-warehouse') {
        // this.leftLinkText = `Let's talk`;
        // this.rightLinkText = 'Next Case';
        // this.leftTitleText = 'Get in touch';
        // this.rightTitleText = 'FinTech Platform';
        // this.rightSideSubtitle =
        //   'That Equips with Data Visualization and Collective Decision Making';

        // this.leftSideColor = '#C4C4C4';
        // this.rightSideColor = '#3B1359';
        // this.leftSideTitleColor = '#656565';
        // this.rightSideTitleColor = '#FFFFFF';
        // this.leftSideLinkColor = '#939292';
        // this.rightSideLinkColor = '#FFFFFF';
        this.leftLinkText = `Let's talk`;
        this.rightLinkText = 'Next Case';
        this.leftTitleText = 'Get in touch';
        this.rightTitleText = 'Online Delivery App';
        this.rightSideSubtitle =
          'Saves Grocery Chain and Restaurants During Lockdown, 80% Of The Code Reused';

        this.leftSideColor = '#C4C4C4';
        this.rightSideColor = '#117444';
        this.leftSideTitleColor = '#656565';
        this.rightSideTitleColor = '#FFFFFF';
        this.leftSideLinkColor = '#939292';
        this.rightSideLinkColor = '#FFFFFF';
      }
      if (event.url && event.url === '/fintech') {
        this.leftLinkText = `Let's talk`;
        this.rightLinkText = 'Next Case';
        this.leftTitleText = 'Get in touch';
        this.rightTitleText = 'Online Delivery App';
        this.rightSideSubtitle =
          'Saves Grocery Chain and Restaurants During Lockdown, 80% Of The Code Reused';

        this.leftSideColor = '#C4C4C4';
        this.rightSideColor = '#117444';
        this.leftSideTitleColor = '#656565';
        this.rightSideTitleColor = '#FFFFFF';
        this.leftSideLinkColor = '#939292';
        this.rightSideLinkColor = '#FFFFFF';
      }
      if (event.url && event.url === '/delivery') {
        // this.leftLinkText = `Let's talk`;
        // this.rightLinkText = 'Next Case';
        // this.leftTitleText = 'Get in touch';
        // this.rightTitleText = 'SOSbooking';
        // this.rightSideSubtitle =
        //   'Leverages on-demand economy for Swiss Communities';

        // this.leftSideColor = '#C4C4C4';
        // this.rightSideColor = '#3D6CCD';
        // this.leftSideTitleColor = '#656565';
        // this.rightSideTitleColor = '#FFFFFF';
        // this.leftSideLinkColor = '#939292';
        // this.rightSideLinkColor = '#FFFFFF';
        this.leftLinkText = `Let's talk`;
        this.rightLinkText = 'Next Case';
        this.leftTitleText = 'Get in touch';
        this.rightTitleText = 'VCM';
        this.rightSideSubtitle = 'for a Global Mobile Communication Company';

        this.leftSideColor = '#C4C4C4';
        this.rightSideColor = '#E60000';
        this.leftSideTitleColor = '#656565';
        this.rightSideTitleColor = '#FFFFFF';
        this.leftSideLinkColor = '#939292';
        this.rightSideLinkColor = '#FFFFFF';
      }
    });
  }

  ngOnDestroy(): void {}

  goToPage(mode: 'left' | 'right') {
    if (mode === 'right') {
      if (this.router.url === '/') {
        this.router.navigateByUrl('/company');
      }
      if (this.router.url === '/company') {
        this.router.navigateByUrl('/delivery-process');
      }
      if (this.router.url === '/delivery-process') {
        this.router.navigateByUrl('/services');
      }
      if (this.router.url === '/services') {
        this.router.navigateByUrl('/technologies');
      }
      if (this.router.url === '/technologies') {
        this.router.navigateByUrl('/portfolio');
      }
      if (this.router.url === '/portfolio') {
        this.router.navigateByUrl('/company');
      }
      if (this.router.url === '/lets-talk') {
        this.router.navigateByUrl('/technologies');
      }
      if (this.router.url === '/sos-booking') {
        this.router.navigateByUrl('/vodafone');
      }
      if (this.router.url === '/vodafone') {
        this.router.navigateByUrl('/fitto');
      }
      if (this.router.url === '/fitto') {
        this.router.navigateByUrl('/zimit');
      }
      if (this.router.url === '/zimit') {
        this.router.navigateByUrl('/basf');
      }
      if (this.router.url === '/basf') {
        this.router.navigateByUrl('/edms');
      }
      if (this.router.url === '/edms') {
        // this.router.navigateByUrl('/sw2');
        this.router.navigateByUrl('/optimized-warehouse');
      }
      if (this.router.url === '/sw2') {
        this.router.navigateByUrl('/optimized-warehouse');
      }
      if (this.router.url === '/optimized-warehouse') {
        // this.router.navigateByUrl('/fintech');
        this.router.navigateByUrl('/delivery');
      }
      if (this.router.url === '/fintech') {
        this.router.navigateByUrl('/delivery');
      }
      if (this.router.url === '/delivery') {
        // this.router.navigateByUrl('/sos-booking');
        this.router.navigateByUrl('/vodafone');
      }
    } else {
      if (this.router.url === '/lets-talk') {
        this.router.navigateByUrl('/services');
      } else {
        this.router.navigateByUrl('/lets-talk');
      }
    }
  }
}
