import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

interface Response {
  result: String;
  status: String;
}

@Component({
  selector: 'app-edms',
  templateUrl: './edms.component.html',
  styleUrls: ['./edms.component.scss'],
})
export class EDMSComponent implements OnInit {
  isShowPopUp: boolean = false;
  isClosedPopUp: boolean = false;
  isShowFormPopup: boolean = false;
  isFormSubmitted: boolean = false;
  sendedForm: number = 0;
  edmsConnectionForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router
  ) {}

  ngOnInit() {
    window.addEventListener('scroll', () => {
      this.showPopUp();
    });

    this.edmsConnectionForm = this.fb.group({
      nameControl: this.fb.control('', [
        Validators.required,
        Validators.minLength(2),
      ]),
      emailControl: this.fb.control('', [
        Validators.required,
        Validators.email,
      ]),
      fileControl: this.fb.control('', [Validators.required]),
    });

    this.edmsConnectionForm.setValue({
      nameControl: '',
      emailControl: '',
      fileControl: 'edms',
    });
  }

  showPopUp() {
    if (this.isClosedPopUp) this.isShowPopUp = false;
    else if (this.router.url == '/edms') {
      const value: number = window.pageYOffset;
      const height: number = window.innerHeight;
      const headerHeight: number = 64; //
      const startValue: number = height - headerHeight;
      let images = document.getElementById('edms-images'),
        cards = document.getElementById('edsm-cards');
      const windowWidth: number = window.innerWidth;

      if (windowWidth < 1119) {
        this.isShowPopUp = true;
      } else {
        if (value > images.offsetTop && value < cards.offsetTop - 64) {
          this.isShowPopUp = true;
        } else {
          this.isShowPopUp = false;
        }
      }
    }
  }

  clickOpenFormEvent() {
    this.isShowFormPopup = true;
    this.isFormSubmitted = false;
  }

  clickCloseFormEvent() {
    this.isShowFormPopup = false;
  }

  clickCloseEvent() {
    this.isClosedPopUp = true;
    this.isShowPopUp = false;
  }

  submitForm(): void {
    this.isFormSubmitted = true;
    const formData: FormData = new FormData();
    formData.append('name', this.edmsConnectionForm.value.nameControl);
    formData.append('email', this.edmsConnectionForm.value.emailControl);
    formData.append('file', 'edms');
    try {
      this.http
        .post('sendMail/sendCaseStudy.php', formData)
        .subscribe((res: Response) => {
          if (res.result === 'success') {
            // alert('Message sent');
            // console.log(res.status)
            // this.basfConnectionForm.reset();
            this.sendedForm = 1;
          } else if (res.result === 'error') {
            // console.log(res.status)
            this.sendedForm = -1;
          }
        });
    } catch (e) {
      console.log(e);
      this.sendedForm = -1;
    }
  }
}
