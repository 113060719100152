import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../shared/module/shared.module';
import { FittoComponent } from './fitto.component';



@NgModule({
  declarations: [FittoComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class FittoModule { }
