import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appParallax]'
})
export class ParallaxDirective {

  @Input('ratio') parallaxRatio = 1;
  initialTop = 0;

  constructor (private eleRef: ElementRef) {
    this.initialTop = this.eleRef.nativeElement.getBoundingClientRect().top;
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    window.requestAnimationFrame(() => {
      if (this.eleRef.nativeElement.id === 'arrow' && window.pageYOffset > 50) {
        this.eleRef.nativeElement.style.opacity = '0';
      } else {
        this.eleRef.nativeElement.style.opacity = '1';
      }
      if (this.eleRef.nativeElement.id === 'bg') {
        this.eleRef.nativeElement.style.opacity = 1 - (window.pageYOffset / 570/*350*/);
      }
      if (this.eleRef.nativeElement.id !== 'arrow') {
        this.eleRef.nativeElement.style.transform = `translateY(${(this.initialTop - (window.scrollY * this.parallaxRatio))}px)`;
      }
    });
  }

}
