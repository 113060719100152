import {NgModule} from '@angular/core';
import {NgxTypedJsModule} from 'ngx-typed-js';
import {CommonModule} from '@angular/common';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material';
import { ParallaxDirective } from '../directives/parallax.directive';

@NgModule({
  declarations: [
    ParallaxDirective
  ],
  imports: [
    NgxTypedJsModule,
    CommonModule,
    SlickCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule
  ],
  exports: [
    NgxTypedJsModule,
    CommonModule,
    SlickCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    ParallaxDirective
  ]
})

export class SharedModule {
  
}
