import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../../shared/module/shared.module';
import { EDMSComponent } from './edms.component';



@NgModule({
  declarations: [EDMSComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class EDMSModule { }
