import { Component, OnInit } from '@angular/core';
import * as Parallax from 'parallax-js';
declare var Parallax: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  caseStudies: any;
  container = {};
  colors = {
    // violet: [90, 105, 213],
    green_bl: [73, 167, 174],
    blue: [0, 119, 188],
    yellow: [249, 180, 15],
    red: [188, 25, 14],
    black: [38, 38, 39],
  };
  TRANSITION_START_PERCENTAGE: number = 0.3;
  TRANSITION_END_PERCENTAGE: number = 0.9;

  slidesContent = [
    {
      id: 1,
      title: 'Business <br> Analysis',
      text:
        'Business strategies set the pace for any business while identifying your competitive advantages and making sure you’re moving in the right direction.<br><br>Our expert Business Analysts are ready to help you develop your business strategy and gain unstoppable momentum!',
      link: '/services/info/1',
      scene:
        '<li class="layer layer-1" data-depth="-1"><img src="assets/images/sphere/Sphere_Layer_02_0024.png"></li><li class="layer layer-1" data-depth="1"><img src="assets/images/sphere/Layer_08_0036.png"></li>',
    },
    {
      id: 2,
      title: 'Mobile and Web <br> Design',
      text:
        'Our team of creative UX/UI designers can bring your idea to life while still considering marketing trends.<br><br>As a UX/UI design company, we are passionate about superior quality, going the extra mile for the clients we serve.',
      link: '/services/info/2',
      scene:
        '<li class="layer layer-1" data-depth="-1"><img src="assets/images/sphere/Sphere_Layer_01_0024.png"></li><li class="layer layer-2" data-depth="1"><img src="assets/images/sphere/Sphere_Layer_02_0024.png"></li><li class="layer layer-2" data-depth="2"><img src="assets/images/sphere/Sphere_Layer_03_0024.png"></li>',
    },
    {
      id: 3,
      title: 'MVP <br> Development',
      text:
        'A savvy way to swiftly penetrate the market, keep costs at a minimum, and satisfy your ideal customer’s needs. <br><br> If you want to achieve this through superior web or mobile app development, we’d be happy to work together to bring your idea to life.',
      link: '/services/info/3',
      scene:
        '<li class="layer layer-1" data-depth="1"><img src="assets/images/sphere/Layer_01_0031.png"></li><li class="layer layer-2" data-depth="0.5"><img src="assets/images/sphere/Layer_02_0031.png"></li><li class="layer layer-3" data-depth="0"><img src="assets/images/sphere/Layer_03_0031.png"></li><li class="layer layer-3" data-depth="-0.5"><img src="assets/images/sphere/Layer_04_0031.png"></li>',
    },
    {
      id: 4,
      title: 'Ongoing <br> Development',
      text:
        'Your devoted team will work hard behind-the-scenes to constantly improve functionality and explore product development. <br><br> It’s all about giving your audience the “perfect product” to meet their needs and expectations.',
      link: '/services/info/4',
      scene:
        '<li class="layer layer-1" data-depth="1.5"><img src="assets/images/sphere/Layer_01_0036.png"></li><li class="layer layer-2" data-depth="1.2"><img src="assets/images/sphere/Layer_02_0036.png"></li><li class="layer layer-3" data-depth="1"><img src="assets/images/sphere/Layer_03_0036.png"></li><li class="layer layer-3" data-depth="0.8"><img src="assets/images/sphere/Layer_04_0036.png"></li><li class="layer layer-3" data-depth="0.6"><img src="assets/images/sphere/Layer_05_0036.png"></li><li class="layer layer-3" data-depth="0.4"><img src="assets/images/sphere/Layer_06_0036.png"></li><li class="layer layer-3" data-depth="0.2"><img src="assets/images/sphere/Layer_07_0036.png"></li><li class="layer layer-3" data-depth="0"><img src="assets/images/sphere/Layer_08_0036.png"></li><li class="layer layer-3" data-depth="-0.2"><img src="assets/images/sphere/Layer_09_0036.png"></li><li class="layer layer-3" data-depth="&#45;&#45;0.4"><img src="assets/images/sphere/Layer_10_0036.png"></li><li class="layer layer-3" data-depth="-0.6"><img src="assets/images/sphere/Layer_11_0036.png"></li><li class="layer layer-3" data-depth="-0.8"><img src="assets/images/sphere/Layer_12_0036.png"></li><li class="layer layer-3" data-depth="-1"><img src="assets/images/sphere/Layer_13_0036.png"></li><li class="layer layer-3" data-depth="-1.2"><img src="assets/images/sphere/Layer_14_0036.png"></li>',
    },
  ];

  constructor() {}

  ngOnInit() {
    this.initCaseAnimations();
    this.initServiceSlider1();
  }

  initServiceSlider1 = () => {
    function initSlider() {
      if (document.body.clientWidth <= 901) {
        let leftBtn = document.querySelector('#btn-left');
        let rightBtn = document.querySelector('#btn-right');
        let slideCount = $('.slide').length;
        let slideWidth = $('.slide').width();
        let slideHeight = $('.slide').height();
        let sliderUlWidth = slideCount * slideWidth;

        $('.services-slider').css({
          width: sliderUlWidth,
          marginLeft: -slideWidth,
        });
        console.log();
        $('.slide:last-child').prependTo('.services-slider');

        leftBtn.addEventListener('click', moveLeft);
        rightBtn.addEventListener('click', moveRight);

        function moveLeft() {
          $('.services-slider').animate(
            {
              left: +slideWidth,
            },
            400,
            function () {
              $('.slide:last-child').prependTo('.services-slider');
              $('.services-slider').css('left', '');
            }
          );
        }

        function moveRight() {
          $('.services-slider').animate(
            {
              left: -slideWidth,
            },
            400,
            function () {
              $('.slide:first-child').appendTo('.services-slider');
              $('.services-slider').css('left', '');
            }
          );
        }
      }
    }

    initSlider();
  };

  initCaseAnimations = () => {
    this.caseStudies = document.querySelectorAll('.case_study');
    this.container = document.querySelector('#case_studies__underlay');
    const color = this.getColorOfCaseStudy(this.caseStudies[0]);
    this.container['style'].backgroundColor = `rgb(${
      (color[0], color[1], color[2])
    }`;
    window.addEventListener('scroll', this.calculateColors);
  };

  calculateColors = () => {
    let base = null;
    let target = null;
    let targetRatio = 0;
    this.caseStudies.forEach((el) => {
      const _a = this.getCasePositionOnTheScreenInPercentage(el);
      const top = _a.top;
      const bottom = _a.bottom;
      if (bottom) {
        base = this.getColorOfCaseStudy(el);
      }
      if (top) {
        target = this.getColorOfCaseStudy(el);
        targetRatio = Math.max(
          0,
          Math.min(
            (1 - top - this.TRANSITION_START_PERCENTAGE) /
              (this.TRANSITION_END_PERCENTAGE -
                this.TRANSITION_START_PERCENTAGE),
            1.0
          )
        );
      }
    });
    if (!base || !target) return;
    const r = Math.round(base[0] - targetRatio * (base[0] - target[0]));
    const g = Math.round(base[1] - targetRatio * (base[1] - target[1]));
    const b = Math.round(base[2] - targetRatio * (base[2] - target[2]));
    this.container['style'].backgroundColor =
      'rgb(' + r + ', ' + g + ', ' + b + ')';
  };

  getCasePositionOnTheScreenInPercentage = (el) => {
    const _a = el.getBoundingClientRect();
    const top = _a.top;
    const bottom = _a.bottom;
    return {
      top: top < window.innerHeight && top > 0 ? top / window.innerHeight : 0,
      bottom:
        bottom < window.innerHeight && bottom > 0
          ? bottom / window.innerHeight
          : 0,
    };
  };

  getColorOfCaseStudy = (el) => {
    let color = this.colors.green_bl; //violet
    Object.keys(this.colors).forEach((key) => {
      if (el.className.includes(key)) {
        color = this.colors[key];
      }
    });
    return color;
  };

  ngAfterContentInit() {
    const scene1 = document.getElementById('scene-1');
    const scene2 = document.getElementById('scene-2');
    const scene3 = document.getElementById('scene-3');
    const scene4 = document.getElementById('scene-4');

    const parallaxInstance1 = new Parallax(scene1, {
      relativeInput: true,
      hoverOnly: true,
    });
    const parallaxInstance2 = new Parallax(scene2, {
      relativeInput: true,
      hoverOnly: true,
    });
    const parallaxInstance3 = new Parallax(scene3, {
      relativeInput: true,
      hoverOnly: true,
    });
    const parallaxInstance4 = new Parallax(scene4, {
      relativeInput: true,
      hoverOnly: true,
    });
  }
}
